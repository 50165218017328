<script>
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import debounce from 'lodash/debounce';
import { mapState } from 'vuex';

import moduleList from '/src/router/views/farms/ModuleList.vue'

export default {
  name: 'filter-dashboard',
  components: {
    moduleList,
  },

  data (){
    return {
      loading: false,
      loadingClients: false,
      loadingFarms: false,
      loadingModules: false,
      loadingPaddocks: false,
      form: {},
      farms: [],
      clients: [],
      modules: [],
    }
  },

  computed: {
    ...mapState('dashboard', ['filter'])
  },

  async created() {
    this.changeFilter = debounce(this.storeChangeFilter, 500)
    this.form.startAt = dayjs().subtract(6, 'month').format('YYYY-MM-DD')
    this.form.endAt = dayjs().format('YYYY-MM-DD')
    await this.fetchFarms()
    await this.fetchClients()
    this.changeFilter()
  },

  methods:{
    storeChangeFilter() {
      this.$store.dispatch('dashboard/changeFilter', this.form)
    },

    async fetchClients() {
        this.loadingClients = true
        try {
        const result = await this.$apollo.query({
          query: gql`
            query farms {
              farms(
                order_by: [{ name: asc }]
              ) {
                customer{
                  id
                  name
                }
              }
            }
          `,
          fetchPolicy: 'no-cache',
        });
        this.clients = result.data.farms.map(item => ({value: item.customer.id, text: item.customer.name, ...item}));
      } catch (e) {
        console.error(e);
      }
      this.loadingClients = false
    },

    async fetchFarms() {
      this.loadingFarms = true
      this.loading = true
      try {
        const result = await this.$apollo.query({
          query: gql`
            query farms {
              farms(
                order_by: [{ name: asc }]
              ) {
                id
                name
                customer{
                  id
                  name
                }
              }
            }
          `,
          fetchPolicy: 'no-cache',
        });
        this.loadingFarms = false
        this.farms = result.data.farms.map(item => ({value: item.id, text: item.name, ...item}));
        let storage = localStorage.getItem('lastFarm')
        if (storage) {
          this.form.farmId = storage
          this.fetchModules()
        } else if (this.farms.length > 0) {
          this.form.farmId = this.farms[0].id
          this.fetchModules()
        }
      } catch (e) {
        console.error(e);
      }
      this.loadingFarms = false
      this.loading = false
    },

    async fetchClientFarms() {
      this.loadingFarms = true
      this.loading = true
      try {
        const result = await this.$apollo.query({
          query: gql`
            query farms ($clientId: uuid!) {
              farms(
                where: {
                  customer_id: { _eq: $clientId }
                },
                order_by: [{ name: asc }]
              ) {
                id
                name
                customer{
                  id
                  name
                }
              }
            }
          `,
          variables: {
            clientId: this.form.clientId
          },
          fetchPolicy: 'no-cache',
        });
        this.loadingFarms = false
        this.farms = result.data.farms.map(item => ({value: item.id, text: item.name, ...item}));
      } catch (e) {
        console.error(e);
      }
    },

    farmFilter (item, queryText) {
      const textOne = item?.name?.toLowerCase()
      const textTwo = item?.customer?.name?.toLowerCase()
      const searchText = queryText.toLowerCase()

      // Validação de texto
      if (!textOne || !textTwo) return false

      // Verifica se contém o texto
      return textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1
    },

    async fetchModules(){
      this.loadingModules = true
      this.loading = true
      try {
        const result = await this.$apollo.query({
          query: gql`
            query modules ($farmId: uuid!) {
              modules(
                where: {
                  farm_id: { _eq: $farmId }
                },
                order_by: [{ name: asc }]
              ) {
                id
                name
                paddocks{
                  id
                  name
                  code
                }
              }
            }
          `,
          variables: {
            farmId: this.form.farmId
          },
          fetchPolicy: 'no-cache',
        });
        this.modulesRaw = result.data.modules;
        this.modules = result.data.modules.map(item => ({value: item.id, text: item.name}));
      } catch (e) {
        console.error(e);
      }
      this.loadingModules = false
      this.loading = false
    },

    handleChangeClient() {
      this.fetchClientFarms()
      this.changeFilter()
    },

    handleChangeFarm() {
      this.form.moduleId = null
      this.form.paddockIds = []
      localStorage.setItem('lastFarm', this.form.farmId)
      this.fetchModules()
      this.changeFilter()
    },
  }
}
</script>

<template>
  <v-card>
    <v-card-text>
        <v-row>
            <v-col sm="3">
            <v-autocomplete
                v-model="form.clientId"
                :items="clients"
                :loading="loadingFarms"
                :filter="farmFilter"
                label="Cliente"
                dense
                outlined
                hide-details
                persistent-placeholder
                @input="handleChangeClient"
            >
            </v-autocomplete>
            </v-col>
            <v-col sm="3">
            <v-autocomplete
                v-model="form.farmId"
                :items="farms"
                :loading="loadingFarms"
                :filter="farmFilter"
                label="Fazenda"
                dense
                outlined
                hide-details
                persistent-placeholder
                @input="handleChangeFarm"
            >
                <template v-slot:item="data">
                <v-list-item-content>
                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    <v-list-item-subtitle v-html="data.item.customer.name"></v-list-item-subtitle>
                </v-list-item-content>
                </template>
            </v-autocomplete>
            </v-col>
        </v-row>
        <br>
        <moduleList v-if="!loading"
        v-model="form.modules"
        :farm-id="form.farmId"
        :farm-name="form.name"
        :customer-id="form.customerId"
        :customer-name="form.customerName"
        />
    </v-card-text>
  </v-card>
</template>